import { Message } from "@graphql/graphql";
import styles from "./message.module.css";
import { ReactionsComponent } from "src/main-page/components/post/reactions/reactions.comp";
import { useEffect, useState, Fragment } from "react";
import { messagesStore, userStore } from "@store";
import { Button, Icon } from "@ui-kit";
import { TypingAnimation } from "../typing-animation/typing-animation.comp";
import { useTranslation } from "react-i18next";
import { MessageContent } from "@shared/components/message-content/message-content.comp";

type Props = {
  messages: Message[],
  onRetryClick: () => void,
  isMe?: boolean,
  className?: string,
}

export const MessageComponent = ({ messages, isMe, className, onRetryClick }: Props) => {
  className = className ? `${className} ${styles.message}` : styles.message;
  className += ` ${!!isMe ? styles.message_me : styles.message_other}`;
  const itemClassName = !!isMe ? styles.message__item_me : styles.message__item_other;

  const { t } = useTranslation('messenger');

  const date = messages[0]?.createdAt ? new Date(messages[0]?.createdAt) : null;
  const dateString = date
    // ? `${date.toLocaleDateString('ru', { weekday: 'long' })} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    : ''

  const [showReactions, setShowReactions] = useState<string|null>();

  useEffect(() => {
    const notReadMessages = messages
      .filter((msg) =>
        msg.chatId !== 'typing'
        && msg.username !== userStore.currentUser?.username
        && !msg.read?.includes(userStore.currentUser?.username ?? '-')
      );
    notReadMessages.forEach((msg) => messagesStore.readMessage(msg.id ?? ''));
    messagesStore.messagesCout -= notReadMessages.length;
  });

  const isRead = (message: Message) =>
    message.username === userStore.currentUser?.username
    && message.read?.filter((read) => read !== userStore.currentUser?.username)?.length;

  const isDelivered = (message: Message) =>
    message.username === userStore.currentUser?.username
    && message.delivered?.filter((read) => read !== userStore.currentUser?.username)?.length
    && !message.delivered.includes('$error')
    && !isRead(message);



  return <div className={className}>
    {/* {!isMe && <Avatar size='md' alt={messages[0].username ?? 'avatar'} url={messages[0].userPicture?.picture?.url} />} */}
    <div className={styles.message__right}>
      {/* <div className={styles.message__header}>
        {!isMe && <h3 className={styles.message__fullname}>
          {messages[0].userPicture?.firstname} {messages[0].userPicture?.lastname}
        </h3>}
        <p className={styles.message__date}>{dateString}</p>
      </div> */}
      <div className={styles['message__item-list']}>
        {messages.map((message) =>
          <Fragment key={message.id}>
            <div
              key={message.id}
              className={itemClassName}
              onClick={() => setShowReactions((prev) => prev === message.id ? '' : message.id)}
            >
              <div className={styles['message__msg-text']}>
                {message.chatId !== 'typing'
                  ?
                    <MessageContent isMe={isMe} text={message.text ?? ''} />
                  : <TypingAnimation />}
              </div>
              {message.chatId !== 'typing' && <div className={styles['message__mgs-right']}>
                <div className={styles['message__msg-time']}>
                  {dateString}
                </div>
                <div className={styles['message__mgs-status']}>
                  {!isRead(message) && !isDelivered(message) && !message.delivered?.includes('$error')
                    && <Icon icon='clock' color='#FFFFFF' size='xxs' />}
                  {!isRead(message) && !!isDelivered(message)
                    && <Icon icon='check' color='#FFFFFF' size='xxs' />}
                  {!!isRead(message)
                    && <Icon icon='double-tick' color='-' size='xxs' />}
                </div>
              </div>}
            </div>
            {!!message.delivered?.includes('$error')
              && <div className={styles['message__error']}>
                <Icon size='xxs' icon='alert-octagon' color='#D92D20' />
                <p className={styles['message__error-text']}>{t('chat.cantSend')}</p>
                <Button
                  size='sm'
                  hierarchy='link-color'
                  destructive={true}
                  className={styles['message__error-button']}
                  onClick={onRetryClick}
                  >{t('button.repeat')}</Button>
              </div>}
              {((!!message.reactions?.length
              && message.reactions.length > 0)
              || showReactions === message.id)
              && message.chatId !== 'typing'
              && <div className={styles.message__reactions}>
                <ReactionsComponent
                  item={message}
                  showReactions={showReactions === message.id}
                  hideOneDigit={true} />
              </div>}
            </Fragment>
        )}
      </div>
    </div>
  </div>
}