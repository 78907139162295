// export const API_URL = "https://ecitty.com";
export const API_URL = "https://ecitty.online";
//  export const API_URL = 'http://localhost:3000';
//  export const API_URL = 'http://192.168.0.106:3000';

// export const API_URL_WS = `wss://api.ecitty.com/graphql`;
export const API_URL_WS = `wss://api.ecitty.online/graphql`;
// export const API_URL_WS = `ws://localhost:4000/graphql`;
// export const API_URL_WS = `ws://192.168.0.106:4000/graphql`;

// export const HOST_URL = `https://ecitty.com`;
export const HOST_URL = `https://ecitty.online`;
// export const HOST_URL = `https://dev-ecitty.netlify.app`;
// export const HOST_URL = `http://localhost:3000`;
// export const HOST_URL = `http://192.168.0.106:3000`;

export const API_URL_GQL = `${API_URL}/graphql`;
export const API_URL_UPLOAD_VIDEO_PATH = `https://api.ecitty.online/upload/video`;
export const API_URL_UPLOAD_IMAGE_PATH = `https://api.ecitty.online/upload/image`;
export const API_URL_GOOGLE_OAUTH_PATH = `/oauth/google`;
