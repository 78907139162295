
import styles from "./ban-user-data.module.css";
import { useTranslation } from "react-i18next";
import { LinkComponent } from "@ui-kit";


export const BanUserData = () => {
  const { t } = useTranslation('wall');

  return   <div className={styles['ban-user__container']}>
  <div className={styles['ban-user']}>
    <div className={styles['ban-user__picture']}></div>
    <div className={styles['ban-user__footer']}>
      <h3 className={styles['ban-user__title']}>{t('ban-user.title')}</h3>
      <div className={styles['ban-user__text']}>{t('ban-user.text')}
        <LinkComponent link='/agreement'>{t('ban-user.link')}</LinkComponent></div>
    </div>
  </div>
</div>
}