import { useEffect, useState } from "react";
import styles from "./admin.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components/header/header.comp";
import { fetchAllReports } from "./helpers/getAllReports";
import { ReportsTable } from "./components/reports-table/reports.table.comp";
import { Report } from "@graphql/graphql";
import { Button } from "@ui-kit";

export const AdminPanelReportsPage = observer(() => {
  const [reports, setReports] = useState<Report[]>([]);
  const [filteredReports, setFilteredReports] = useState<Report[]>([]);
  const [activeButton, setActiveButton] = useState('all');

  useEffect(() => {
    const loadReports = async () => {
      try {
        const allReports = await fetchAllReports();
        const sortedReports = allReports.sort(
          (a: Report, b: Report) => Number(a.isApprooved) - Number(b.isApprooved)
        );
        setReports(sortedReports);
        setFilteredReports(sortedReports);
      } catch (error) {
        console.error('Error getting all reports:', error);
      }
    };
    loadReports();
  }, []);

  const handleButtonClick = (filter: string) => {
    setActiveButton(filter);

    let filtered: Report[] = [];

    if (filter === 'all') {
      filtered = reports;
    } else if (filter === 'waiting') {
      filtered = reports.filter((report) => !report.isApprooved);
    } else if (filter === 'completed') {
      filtered = reports.filter((report) => report.isApprooved);
    }

    setFilteredReports(filtered);
  };

  return (
    <>
      <section className={styles['admin-page']}>
        <Header
          className={styles['header-admin']}
          title='Жалобы'
          hideAvatar={true}
          subTitle={`${filteredReports.length}`}
        />

        <section className={styles['reports-buttons']}>
          <Button
            size='sm'
            hierarchy={activeButton === 'all' ? 'secondary-color' : 'secondary-gray'}
            onClick={() => handleButtonClick('all')}
          >
            Все
          </Button>
          <Button
            size='sm'
            hierarchy={activeButton === 'waiting' ? 'secondary-color' : 'secondary-gray'}
            onClick={() => handleButtonClick('waiting')}
          >
            Ожидание
          </Button>
          <Button
            size='sm'
            hierarchy={activeButton === 'completed' ? 'secondary-color' : 'secondary-gray'}
            onClick={() => handleButtonClick('completed')}
          >
            Завершено
          </Button>
        </section>

        <section className={styles['reports-container']}>
          <ReportsTable reports={filteredReports} />
        </section>
      </section>
    </>
  );
});