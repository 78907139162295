import { banUser } from "@graphql/docs/mutations";
import { apolloClient } from "src/apollo-client";

export const adminBanUser = async (username: string) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: banUser,
      variables: { input: { username } },
    });
    return data;
  } catch (error) {
    console.error('Error block user:', error);
    throw error;
  }
};