
export const getNotificationReport = (type: string, decitionType: string, t: any) => {

  switch(type) {
    case 'report-to-user':
      switch (decitionType) {
        case 'warning':
          return t('message.report.user.warning');

        case 'delete':
          return t('message.report.user.delete');

        case 'delete-warning':
          return t('message.report.user.delete-warning');

        case 'block':
          return t('message.report.user.block');

        case 'delete-block':
         return t('message.report.user.delete-block');

        default: return 'error'
      }

    case 'report-to-reporter':
      switch (decitionType) {
        case 'noProof':
          return t('message.report.reporter.no-proof');

        case 'warning':
          return t('message.report.reporter.warning');

        case 'delete':
          return t('message.report.reporter.delete');

        case 'delete-warning':
          return t('message.report.reporter.delete-warning');

        case 'block':
          return t('message.report.reporter.block');

        case 'delete-block':
          return t('message.report.reporter.delete-block');

        default: return 'error'
      }
  }
}