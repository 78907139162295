import { Report, UpdateReportInput } from "@graphql/graphql";
import { updateReport } from "@graphql/docs/mutations";
import { apolloClient } from "src/apollo-client";
import { toastsStore } from "@store";

export async function finalReportDecision (input: UpdateReportInput): Promise<Report|null> {
  try {
    const { data } = await apolloClient.mutate({
      mutation: updateReport,
      variables: { input }
    });
      toastsStore.addSuccessToast('Жалоба обработана');
      return data;
    } catch {
      toastsStore.addErrorToast('Не удалось обработать жалобу!');
      return null;
    }
  };