import { Report, UserPicture } from "@graphql/graphql";
import { Button } from "@ui-kit";
import { modalCustomDialogsStore } from "@store";
import { FinalReportModal } from "../final-report-modal/final-report-modal";

type Props = {
  report: Report;
  username?: UserPicture;
  reporterUsername?: UserPicture;
};

export const WarningReportButton = ({ report, username, reporterUsername }: Props) => {

  const handleButtonClick = () => {
    modalCustomDialogsStore.showDialog(
      <FinalReportModal
        report={report}
        username={username}
        reporterUsername={reporterUsername}
        typeDecision='warning'
        closeModal={() => modalCustomDialogsStore.hideDialog()}/>,
      {
        title: 'Финальный вердикт',
        message: `Обращение #${report.id}`,
      }
    );
  };

  return (
    <Button
      hierarchy='secondary-gray'
      size='sm'
      onClick={handleButtonClick}
    >
      Предупреждение
    </Button>
  );
};
