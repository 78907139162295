import { HashTag } from "@graphql/graphql";
import styles from "./hashtag.module.css";
import { useTranslation } from "react-i18next";
import { getCountType } from "src/main-page/components/post/helpers/get-count-type";

type Props = {
  hash: HashTag,
  onClick?: () => void,
};

export const HashtagComponent = ({ hash, onClick }: Props) => {
  const { t } = useTranslation('search');

  return (
    <div onClick={onClick}>
      <div className={styles['hash-component']}>
        <p className={styles['hash-title']}>
          {hash.hashtag}
        </p>
        <span className={styles['hash-subtitle']}>
          {hash.length ? t(`hashs.posts.count.${getCountType(hash.length)}`, { count: hash.length }) : ''}
        </span>
      </div>
    </div>
  );
};
