import { useEffect, useState } from "react";
import styles from "./admin.module.css";
import { observer } from "mobx-react-lite";
import { userStore } from "@store";
import { UserPicture } from "@graphql/graphql";
import { Header } from "@shared/components/header/header.comp";
import { UsersTable } from "./components/users-table/users.table.comp";

export const AdminPanelUsersPage = observer(() => {
  const [users, setUsers] = useState<UserPicture[]>([]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const data = await userStore.getAllUsers();
        setUsers(data ?? []);
      } catch (error) {
        console.error('Error get all users:', error);
      }
    };
    getAllUsers();
  }, []);

  return (
    <>
      <section className={styles['admin-page']}>
        <Header
          className={styles['header-admin']}
          title={'Пользоватери'}
          hideAvatar={true}
          subTitle={`${users.length}`}
        />
        <section className={styles['users-container']}>
          <UsersTable users={users} />
        </section>
      </section>
    </>
  );
});