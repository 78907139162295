import { Report } from "@graphql/graphql";
import { HOST_URL } from "@shared/data/api.constants";

export const getReportContent = (report: Report) => {
  if (!report.post && !report.comment) {
    return `${HOST_URL}/wall/${report.reporterUsername}`;

  } else if (report.post && !report.comment) {
    return `${HOST_URL}/post/${report.post?.id}`;

  } else if (!report.post && report.comment) {
    return `${HOST_URL}/post/${report.comment?.postId}?comment=${report.comment?.id}`;

  } else {
    return undefined;
  }
};
