import { Report } from "@graphql/graphql";

export const handleTypeReport = (report: Report): string => {
  switch (report.reasonType) {
    case 'offense':
      if(!report.post && !report.comment) {
        return 'Профиль оскорбителен'
      }
      return report.post ? 'Пост оскорбителен' : 'Комментарий оскорбителен';

    case 'inappropriate':
        return report.post ? 'Пост неуместен' : 'Комментарий неуместен';

    case 'spam':
      if(!report.post && !report.comment) {
        return 'Профиль распространяет спам и мошенничество'
      }

      return report.post ? 'Пост содержит спам или мошенничество' : 'Комментарий содержит спам или мошенничество';

    case 'other':
      return 'Свой вариант';
    default:
      return 'Свой вариант';
  }
};