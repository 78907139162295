import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./reporter.page.module.css";
import { observer } from "mobx-react-lite";
import { ReporterHeader } from "./components/reporter-header/reporter-header.comp";
import { UserPicture, Report } from "@graphql/graphql";
import { userStore } from "@store";
import { Loader, Badge } from "@ui-kit/components/";
import { fetchGetReportsByReporter } from "../../helpers/getReportsByReporter";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { handleTypeReport } from "../../helpers/handleTypeReport";
import { formatDate } from "@ui-kit/components/calendar/helpers";
import { ReportHeader } from "./components/report-header/report-header.comp";
import { formatTime } from "../../helpers/formatTime";
import { ReportProfile } from "../reporter/components/report-profile/report-profile.comp";
import { CommentComponent } from "src/main-page/components/comment/comment.comp";
import { FollowReportButton, WarningReportButton, DeleteReportButton, BlockReportButton, CloseReportButton } from "./components/buttons/";

export const AdminPanelReporterPage = observer(() => {
  const { username } = useParams<{ username: string }>();

  const [reporterUsername, setReporterUsername] = useState<UserPicture>();
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<Report[]>([]);
  const [users, setUsers] = useState<{ [key: string]: UserPicture | undefined }>({});

  const navigate = useNavigate();

  const loadReporter = async (username: string) => {
    setIsLoading(true);
    try {
      const data = await userStore.getUserData(username);
      const reports = await fetchGetReportsByReporter(username);
      if (data) setReporterUsername(data);

      const sortedReports = reports.sort((a: Report, b: Report) => Number(a.isApprooved) - Number(b.isApprooved));
      if (sortedReports) {
        setReports(sortedReports);
        const userData: { [key: string]: UserPicture | undefined } = {};

        await Promise.all(
          reports.map(async (report: Report) => {
            if (report.username) {
              const data = await userStore.getUserData(report.username);
              if (data) {
                userData[report.username] = data;
                setUsers(userData);
              }
            }
          })
        );
      }
    } catch (error) {
      console.error('loading reports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (username) {
      loadReporter(username);
    }
  }, [username]);

  const onBackClick = () => {
    if (!!window.history.state.idx) {
      navigate(-1);
    } else {
      navigate('/admin');
    }
  };

  if (isLoading) {
    return (
      <div className={styles['loading']}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <ReporterHeader userPicture={reporterUsername} onBackClick={onBackClick} />
      <section className={styles['reports-container']}>
        {reports.length ? (
          reports.map((report) => (
            <React.Fragment key={report.id}>
              <div className={styles['report-info']}>
                <div className={styles['report-data']}>
                  <div className={styles['date-line']}></div>
                  <Badge
                    key={report.id}
                    customColor='#F2F4F7'
                    customTextColor='#344054'
                  >
                    {report.createdAt ? formatDate(new Date(report.createdAt), 'D MMM YYYY') : 'Дата неизвестна'}
                  </Badge>
                  <div className={styles['date-line']}></div>
                </div>

                <div className={styles['report-reporter']}>
                  <div className={styles['report-time']}>{report?.createdAt && formatTime(report.createdAt)}</div>
                  {report.username && users[report.username] ? (
                    <ReportHeader key={report.id} reporterUsername={reporterUsername} username={users[report.username]} />
                  ) : (
                    <div>User not found</div>
                  )}
                </div>

                <div className={styles['report-status']}>
                  <p className={styles['title-status']}>Статус:</p>
                  <Badge
                    key={report.username}
                    customColor={report.isHandled ? '#ECFDF3' : '#F2F4F7'}
                    customTextColor={report.isHandled ? '#027A48' : '#344054'}
                  >
                    {report.isHandled ? 'Завершено' : 'Ожидание'}
                  </Badge>
                </div>

                {report.isHandled && (
                  <div className={styles['report-decision']}>
                    <p className={styles['title-decision']}>Вердикт:</p>
                    {report.decisionText}
                  </div>
                )}

                <div className={styles['report-reason']}>
                  <p className={styles['title-decision']}>Тема:</p>
                  {handleTypeReport(report)}
                </div>

                {report.reason && (
                  <div className={styles['report-reason']}>
                    <p className={styles['title-decision']}>Текст:</p>
                    {report.reason}
                  </div>
                )}
              </div>

              <div className={styles['report-content']}>
                {report.post && <PostComponent key={report.post.id} item={report.post} />}
                {!report.post && !report.comment && report.username && <ReportProfile username={users[report.username]} />}
                {report.comment && (
                  <div className={styles['report-content__comment']}>
                    <CommentComponent item={report.comment} />
                  </div>
                )}
              </div>

              {!report.isApprooved && (
                <div className={styles['report-buttons']}>
                  <FollowReportButton report={report} />
                  {report.username && users[report.username] && (
                    <>
                      <CloseReportButton reporterUsername={reporterUsername} username={users[report.username]} report={report} />
                      <WarningReportButton reporterUsername={reporterUsername} username={users[report.username]} report={report} />
                      {(report.post || report.comment) && (
                        <DeleteReportButton reporterUsername={reporterUsername} username={users[report.username]} report={report} />
                      )}
                      {!report.post && !report.comment && (
                        <BlockReportButton reporterUsername={reporterUsername} username={users[report.username]} report={report} />
                      )}
                    </>
                  )}
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <div>No reports available.</div>
        )}
      </section>
    </>
  );
});