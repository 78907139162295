import { Avatar } from "@ui-kit";
import styles from "./chat-item.module.css";
import { Chat } from "@graphql/graphql";
import { messagesStore, userStore } from "@store";
import { PremiumIcon } from "@shared/components/premium-icon/premium-icon.comp";
import { getMessageTime } from "src/messenger/helpers/get-message-time";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { MESSAGE_MENU_KEYS, useMessageMenu } from "src/messenger/helpers/use-message-menu";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { reportUser } from "@shared/helpers/report-user";
import { useMessageMenuClick } from "src/messenger/helpers/use-message-menu-click";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { MessageContent } from "@shared/components/message-content/message-content.comp"

type Props = {
  chat: Chat,
  selected?: boolean,
  onClick: () => void
}

export const ChatItem = observer(({ chat, selected, onClick }: Props) => {
  const user = chat?.userPicture?.find((user) => user?.username !== userStore.currentUser?.username);

  const { t } = useTranslation('messenger');

  const onMessageMenuClick = useMessageMenuClick();
  const getMessageMenu = useMessageMenu();


  const isNotRead =
    chat.lastMessage?.username !== userStore.currentUser?.username
    && !chat.lastMessage?.read?.includes(userStore.currentUser?.username ?? '')

  const classArr = [styles['chat-item']];
  if (isNotRead) {
    classArr.push(styles['chat-item_not-read']);
  }

  if (selected) {
    classArr.push(styles['chat-item_selected']);
  }

  const onChatClick = (e: any) => {
    const classList = [...e.target.classList];
    if (
      classList.includes(styles['chat-item'])
      || classList.includes(styles['chat-item__title'])
      || classList.includes(styles['chat-item__fullname'])
      || classList.includes(styles['chat-item__premium'])
      || classList.includes(styles['chat-item__time'])
      || classList.includes(styles['chat-item__message-text'])
    ) {
      onClick();
    }
  }

  return <div className={classArr.join(' ')} onClick={onChatClick}>
    <Avatar
      size='lg'
      url={user?.picture?.url}
      alt={user?.username ?? 'user avatar'}
      statusIcon={
        !!chat.userPicture
          ?.find((name) => userStore.currentUser?.username !== name?.username)
          ?.isOnline
            ? 'online'
            : undefined
      } />
    <div className={styles['chat-item__right']}>
      <div className={styles['chat-item__title']}>
        <h3 className={styles['chat-item__fullname']}>{user?.firstname} {user?.lastname}</h3>
        {!!user?.isPremiun && <PremiumIcon size='sm' className={styles['chat-item__premium']} />}
        <p className={styles['chat-item__time']}>
          {getMessageTime(chat.lastMessage?.createdAt ?? chat.createdAt ?? 0)}
        </p>
      </div>
      <p className={styles['chat-item__message-text']}>
      {chat.lastMessage?.text ? (
        <MessageContent text={chat.lastMessage.text} />
          ) : (
        t('chat.media.file')
      )}
      </p>
    </div>
    <div className={styles['chat-item__menu']}>
      <ItemMenu
        className={styles['chat-item__more-menu']}
        toRight={true}
        items={getMessageMenu(chat, userStore.currentUser?.username)}
        onClick={(item) => onMessageMenuClick(item, chat)} />
    </div>
  </div>
});
