import { Comment, CommentWithPost, FullReaction, Post, Reaction } from "@graphql/graphql";
import "moment/locale/ru";
import moment from "moment";

export const getCorrectPostDate = (item: Post|Comment|Reaction|FullReaction|CommentWithPost|{ createdAt: number }) => {
  const dateMs = item.createdAt ?? 0;
  const date = new Date(dateMs);

  const relativeTime = moment(date).fromNow();

  const formattedDate = moment(date).format("DD.MM.YYYY в HH:mm");

  return {relativeTime, formattedDate};
}