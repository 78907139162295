import styles from "./create-message.module.css";
import { messagesStore, userStore } from "@store";
import { useEffect, useRef, useState } from "react";
import { Button } from "@ui-kit";
import { observer } from "mobx-react-lite";
import { createNewChat } from "../../helpers/create-new-chat";
import { TYPING_TIMEOUT } from "src/messenger/data/message.constants";
import { useTranslation } from "react-i18next";
import { ReactionsTextComponent } from "src/main-page/components/post/reactions-text/reactions-text.comp";
import { ReactionTypes } from "src/assets/reactions";
import { insertEmoji, saveSelection } from "src/main-page/helpers/insert-emoji";
import { emojiUnicodes } from "@shared/data";

type Props = {
  chatId?: string,
  className?: string,
}

let timeoutId: any;


export const CreateMessage = observer(({ chatId, className }: Props) => {
  className = className ? `${className} ${styles['create-message']}` : styles['create-message'];

  const { t } = useTranslation('messenger');

  const [text, setText] = useState('');
  const [typing, setTyping] = useState(false);
  const [showReactions, setShowReactions] = useState(false);

  const setInput = (key: 'text', value: string) => {
    if (key === 'text') {
      setText(value);
    }
  };

  const textRef: any = useRef();

  const onInput = () => {
    setTyping(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTyping(false);
    }, TYPING_TIMEOUT);
    setText((
      textRef
        ?.current
        ?.innerHTML
        ?.replace(/<img[^>]+alt="(\w+)"[^>]*>/gi, (match: string, alt: string) => emojiUnicodes[alt] || '')
        ?.replaceAll('&nbsp;', ' ')
        ?.replaceAll('&lt;', '<')
        ?.replaceAll('&gt;', '>')
        ?.replace(/<[^>]*>/g, '')
      ?? ''
    ) as string);

    saveSelection();
  }


  const handleKeyUp = () => {
    saveSelection();
  }

  const handleMouseUp = () => {
    saveSelection();
  };


  const sendMessage = async () => {
    setTyping(false);
    const chat = await messagesStore.chats.find((chat) => chat.chatId === chatId);

    if (chat && chatId) {
      messagesStore.sendMessage({
        chatId,
        text,
      }).then(() => {
        setText('');
        textRef.current.innerHTML = '';
      });
    } else {
      const user = await userStore.getUserData(chatId ?? '');
      if (!user?.username) {
        return
      }
      createNewChat(user?.username).then((res) => {
        if (!res?.chatId) {
          return;
        }
        messagesStore.sendMessage({
          chatId: res.chatId,
          text,
        }).then(() => {
          setText('');
          textRef.current.innerHTML = '';
        });
      })
    }
  }

  useEffect(() => {
    setText('');
    textRef.current.innerHTML = '';
  }, []);

  useEffect(() => {
    if (!chatId) {
      return;
    }

    if (typing) {
      messagesStore.startTyping(chatId);
    } else {
      messagesStore.stopTyping(chatId);
    }
  }, [typing]);



  const handleEmojiSelect = (emoji: ReactionTypes) => {
    insertEmoji({ textRef, emoji, setInput });
  };

  const openEmojiSelector = () => {
    saveSelection();
    setShowReactions(true);
  };

  return <div className={`{styles['create-message-bottom']} ${className}`}>
    <div className={styles['create-message__input-container']}>
      <div
        ref={textRef}
        contentEditable='true'
        className={styles['create-message__input']}
        onInput={onInput}
        onKeyUp={handleKeyUp}
        onMouseUp={handleMouseUp}
      />
      {!textRef?.current?.innerHTML && <div
        className={styles['create-message__input_placeholder']}
      >
        {t('button.createMessage')}
      </div>}
    </div>
      <Button
        size='lg'
        hierarchy='secondary-gray'
        iconType='smile'
        onClick={() => setShowReactions((prev) => !prev)} />

    <Button
      size='lg'
      hierarchy='primary'
      iconType='send'
      loading={messagesStore.loading}
      disabled={!textRef?.current?.innerHTML}
      onClick={sendMessage} />

      {showReactions && <ReactionsTextComponent
        isMessage={true}
        setShowReactions={openEmojiSelector}
        showReactions={showReactions}
        onEmojiSelect={handleEmojiSelect} />}
  </div>
});
