import { getAllReports } from "@graphql/docs/queries";
import { apolloClient } from "src/apollo-client";

export async function fetchAllReports() {
  try {
    const { data } = await apolloClient.query({
      query: getAllReports,
    });
    return data.getAllReports;
  } catch (error) {
    console.error('Error fetching all reports:', error);
    throw error;
  }
};