import { urlRegExp } from "@home";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import styles from "./message-content.module.css";
import { renderTextEmoji } from "@shared/helpers/render-text-emoji";

type Props = {
  text: string,
  isMe?: boolean,
}

export const MessageContent = ({ text, isMe }: Props) => {
  const [components, setComponents] = useState<any[]>([]);

  useEffect(() => {
    const lines = text.split('\n');

    Promise.all(lines.map(async (line, i) => {
      const words = await Promise.all(line.split(/(\s+)/).map(async (word) => {
        if (word.match(urlRegExp)) {
          return <Fragment key={`${uuid()}-${i}`}><a href={word}>{word}</a> </Fragment>
        }

        if (word[0] === '#') {
          return <Fragment key={`${uuid()}-${i}`}>
            <Link className={`${styles.link} ${isMe ? styles['link-me'] : styles['link-other']}`} to={`/search?q=%23${word.substring(1)}`}>{word}</Link> </Fragment>
        }

        const emoji = renderTextEmoji(word);
        if (emoji.some((part) => typeof part !== 'string')) {
          return <Fragment key={`${uuid()}-${i}`}>{emoji}</Fragment>;
        }

        return <Fragment key={`${uuid()}-${i}`}>{word} </Fragment>
      }));
      return <span key={`${uuid()}-${i}`}>{words}</span>
    }))
      .then(setComponents);
  }, []);

  return <>{components}</>
}