import { Icon } from "@ui-kit";
import styles from "./blocked-chat.module.css";
import { useTranslation } from "react-i18next";

export const BlockedChat = () => {
  const { t } = useTranslation('messenger');

  return <div className={styles['blocked-chat']}>
    <Icon
      borderColor='#D92D20'
      bgColor='#F04438'
      color='#FFFFFF'
      option='circle-shadow'
      size='xxs'
      icon='alert-circle' />
    <p className={styles['blocked-chat__text']}>
      {t('chat.userblocked')}
    </p>
  </div>
}