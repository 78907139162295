import { Comment } from "@graphql/graphql";
import styles from "./comment.module.css";
import { Avatar, Button, Badge } from "@ui-kit";
import { ReactionsComponent } from "../post/reactions/reactions.comp";
import { getCorrectPostDate } from "../post/helpers/get-correct-post-date";
import { observer } from "mobx-react-lite";
import { SubCommentList } from "../sub-coment-list/sub-coment-list.comp";
import { ContentData } from "@shared/components/content-data/content-data.comp";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { COMMENT_MENU_KEYS, useCommentMenu } from "../../helpers/useCommentMenu";
import { commentListStore, modalCustomDialogsStore, toastsStore, userStore } from "@store";
import { useState } from "react";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { hideService } from "@shared/services/hide.service";
import { ReportModalContent } from "@shared/components/report-modal-content/report-modal-content.com";
import { ShareDialog } from "@shared/components/share-dialog/share-dialog.comp";
import { HOST_URL } from "@shared/data/api.constants";
import { useTranslation } from "react-i18next";

type Props = {
  item?: Comment,
  className?: string,
  onAnswerSubClick?: (username: string) => void,
  onAnswerClick?: () => void,
  subComment?: Comment|null,
}

export const CommentComponent = observer(({
  item, className, onAnswerClick, onAnswerSubClick, subComment,
}: Props) => {
  className = className ? `${className} ${styles.comment}` : styles.comment;

  const { t } = useTranslation('main');

  const getCommentMenu = useCommentMenu();

  const [showComment, setShowComment] = useState(true);
  const [comment, setComment] = useState(item);

  const onShareClick = () => {
    const url = comment?.postId
      ? `${HOST_URL}/post/${comment.postId}`
      : HOST_URL;
    modalCustomDialogsStore.showDialog(
      <ShareDialog url={url} />,
      {
        title: t('dialogs.share.title'),
        secondButtonText: t('dialogs.share.button'),
        secondButtonColor: 'secondary-color',
      }
    )
  }

  const onMenuClick = (menuItem: MenuItem) => {
    switch(menuItem.key) {
      case COMMENT_MENU_KEYS.DELETE:
        modalCustomDialogsStore.showDialog(
          undefined,
          {
            title: t('dialogs.deleteComment.title'),
            message: t('dialogs.deleteComment.message'),
            firstButtonText: t('dialogs.deleteComment.firstButton'),
            firstButtonColor: 'secondary-color',
            secondButtonText: t('dialogs.deleteComment.secondButton'),
            secondButtonColor: 'primary',
            secondButtonDestructive: true,
            confirmAction: () => {
              if (comment?.id) commentListStore.deleteCommentFn(comment.id)
                .then((res) => res !== null && setShowComment(false))
                .catch((err) => {
                  toastsStore.addErrorToast(t('toasts.error.deleteComment'));
                });
            },
          },
        );
        break;
      case COMMENT_MENU_KEYS.FOLLOW:
        if (comment?.username) {
          userStore.follow(comment.username)
            .then(() => setComment({ ...comment, follow: true }))
        }
        break;
      case COMMENT_MENU_KEYS.UNFOLLOW:
        if (comment?.username) {
          userStore.unfollow(comment.username)
            .then(() => setComment({ ...comment, follow: false }))
        }
        break;
      case COMMENT_MENU_KEYS.HIDE:
        hideService.hideComment(comment?.id)
          .then((res) => {
            if (res) {
              setShowComment(false)
            }
          });
        break;
      case COMMENT_MENU_KEYS.LINK:
        onShareClick();
        break;
      case COMMENT_MENU_KEYS.REPORT:
        modalCustomDialogsStore.showDialog(
          <ReportModalContent item={comment} closeModal={() => modalCustomDialogsStore.hideDialog()} />,
          {
            title: t('dialogs.reportComment.title'),
          },
        )
        break;
    }
  }

  if (!comment || comment.isHiden) {
    return <></>
  }

  return <div className={`${className} ${showComment ? '' : styles['comment__hide']}`}>
    <Avatar
      className={styles.comment__avatar}
      size='sm'
      url={comment.userPicture?.picture?.url}
      alt={comment.userPicture?.username ?? 'user'}
      statusIcon={comment.userPicture?.isOnline ? 'online' : undefined} />
    <div className={styles.comment__right}>
      <div className={styles.comment__header}>
        <h3 className={styles.comment__fullname}>
          {comment.userPicture?.firstname} {comment.userPicture?.lastname}
        </h3>
        <p className={styles.comment__time}> {comment ? getCorrectPostDate(comment).relativeTime : ''}</p>
        {userStore.currentUser?.username === comment.username &&
          <Badge size='sm' color='primary'>{t('leaveComment.badge')}</Badge>}
        <ItemMenu
          className={styles['comment__item-menu']}
          items={getCommentMenu(comment, userStore.currentUser?.username ?? '')}
          onClick={onMenuClick} />
      </div>
      <div className={styles.comment__text}>
        <ContentData text={comment.text ?? ''} usernames={comment.answer as string[] ?? undefined} />
      </div>
      <div className={styles.comment__footer}>
        <Button size='lg' hierarchy='link-gray' onClick={onAnswerClick}>{t('leaveComment.button')}</Button>
        <ReactionsComponent isComment={true} item={comment} showReactions={true} />
      </div>
      {!!comment.commentsLength && onAnswerSubClick && <div className={styles['comment__answer-list']}>
        <SubCommentList
          firstSubComment={subComment}
          commentId={comment.id}
          length={comment.commentsLength}
          onAnswerSubClick={onAnswerSubClick} />
      </div>}
    </div>
  </div>
});
