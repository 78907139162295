import styles from "./user-wall.module.css";
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { modalCustomDialogsStore, userStore } from "@store";
import { UserPicture } from "@graphql/graphql";
import { UserProfileHeader } from "./component/user-profile-header/user-profile-header.comp";
import { UserPosts } from "./component/user-posts/user-posts.comp";
import { Header } from "@shared/components/header/header.comp";
import { TabItem, Tabs } from "@ui-kit/components/tabs/tabs.comp";
import { LikedPosts } from "./component/liked-posts/liked-posts.comp";
import { UserComments } from "./component/user-comments/user-comments.comp";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { PROFILE_MENU_KEYS, useProfileMenu } from "./helpers/use-profile-menu";
import { ReportModalContent } from "@shared/components/report-modal-content/report-modal-content.com";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { ShareDialog } from "@shared/components/share-dialog/share-dialog.comp";
import { HOST_URL } from "@shared/data/api.constants";
import { useTranslation } from "react-i18next";
import { BanUserData } from "./component/ban-user-data/ban-user-data.comp";

export const UserWallPage = () => {
  const { t } = useTranslation('wall');

  const tabs = [
    { key: 'posts', title: t('tabs.posts') },
    { key: 'comments', title: t('tabs.comments') },
    { key: 'liked', title: t('tabs.liked') },
  ]

  const { username } = useParams();

  const getProfileMenu = useProfileMenu();

  const [user, setUser] = useState<UserPicture>();
  const [loading, setLoading] = useState(true);
  const [curentTab, setCurrentTab] = useState<TabItem>(tabs[0]);

  const onShareClick = () => {
    const url = user?.username
      ? `${HOST_URL}/wall/${user.username}`
      : HOST_URL;
    modalCustomDialogsStore.showDialog(
      <ShareDialog url={url} />,
      {
        title: t('dialogs.sharePost.title'),
        secondButtonText: t('dialogs.sharePost.secondButton'),
        secondButtonColor: 'secondary-color',
      }
    )
  }

  const onMenuClick = (menuItem: MenuItem) => {
    switch(menuItem.key) {
      case PROFILE_MENU_KEYS.LINK:
        onShareClick();
        break;
      case PROFILE_MENU_KEYS.REPORT:
        modalCustomDialogsStore.showDialog(
          <ReportModalContent item={user} closeModal={() => modalCustomDialogsStore.hideDialog()} />,
          {
            title: t('dialogs.report.title'),
            message: t('dialogs.report.message')
          },
        )
        break;
    }
  }

  const loadUserData = () => {
    if (!username) {
      return;
    }

    setLoading(true);
    userStore.getUserData(username)
      .then((user) => {
        if (user) {
          setUser(user);
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    document.body.scrollTo({top: 0, behavior: 'auto'});
    loadUserData();
  }, [username]);

  return <>
    <OgMetaData type='user' user={user} />
    <Header
      title={username ? `@${username}` : t('header.userPage')}
      hideAvatar={true}
    >
      {userStore.currentUser && !user?.banned
        && user?.username !== userStore.currentUser.username
        && <ItemMenu
          className={styles['item-menu']}
          items={getProfileMenu(user, userStore.currentUser?.username ?? '')}
          onClick={onMenuClick} />}
    </Header>
    {user?.banned ? <BanUserData/> :
    <>
    {loading
      ? <div className={styles.loader}>
          <Loader />
        </div>
      : <UserProfileHeader user={user} reloadUserData={loadUserData} />}
    <Tabs
      className={styles.tabs}
      items={tabs}
      current={curentTab}
      onClick={(key: string) => setCurrentTab(tabs.find((tab) => tab.key === key) ?? tabs[0])}/>
    <Tabs
      className={styles['tabs-mobile']}
      size='sm'
      items={tabs}
      current={curentTab}
      onClick={(key: string) => setCurrentTab(tabs.find((tab) => tab.key === key) ?? tabs[0])}/>
    {curentTab.key === 'posts' && <UserPosts username={username} />}
    {curentTab.key === 'comments' && <UserComments username={username} />}
    {curentTab.key === 'liked' && <LikedPosts username={username} />}
    </>}
  </>
}