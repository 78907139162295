import React, { useState, useEffect } from "react";
import styles from "./../../admin.module.css";
import { UserPicture, Role } from "@graphql/graphql";
import { userStore } from "@store";
import { Loader, Badge, Avatar } from "@ui-kit/components/";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";

interface Props {
  users: UserPicture[];
}

export const UsersTable = ({ users }: Props) => {
  const [userRoles, setUserRoles] = useState<{ [key: string]: Role }>({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserRole = async () => {
      setIsLoading(true);
      const roles: { [key: string]: Role } = {};

      await Promise.all(
        users.map(async (user) => {
          const username = user.username;
          if (username) {
            const data = await userStore.getUserData(username);
            if (data?.roles) {
              const role = data.roles.find((role) => role);
              roles[username] = {
                title: role?.title || 'Обычный',
                color: role?.color,
                textColor: role?.textColor,
              };
            } else {
              roles[username] = { title: 'Обычный' };
            }
          }
        })
      );

      setUserRoles(roles);
      setIsLoading(false);
    };

    loadUserRole();
  }, [users]);

  const tableHeaders = ['ID', 'Аватар', 'Фамилия/Имя', 'Логин', 'Пользователь', 'Роль', 'Город', 'Действия'];

  if (isLoading) {
    return (
      <div className={styles['loading']}>
        <Loader loaderType='line' />
      </div>
    );
  }

  return (
    <div className={styles['grid-table']}>
      <div className={styles['table-header']}>
        {tableHeaders.map((header, index) => (
          <div key={index} className={styles['header-cell']}>
            {header}
          </div>
        ))}
      </div>

      {users.map((user) => (
        <div className={styles['table-user-info']} key={user.username}>
          <div className={styles['table-cell']}>{'...'}</div>

          <div className={styles['table-cell-avatar']} onClick={() => navigate(`/wall/${user.username}`)}>
            <Avatar url={user.picture?.url} size='xs' />
          </div>

          <div className={styles['table-cell']}>
            {user?.firstname} {user?.lastname}
          </div>

          <div className={styles['table-cell']}>{user.username}</div>

          <div className={styles['table-cell']}>
            <Badge
              key={user.username}
              customColor={user.banned ? '#B42318' : '#ECFDF3'}
              customTextColor={user.banned ? '#FFFFFF' : '#027A48'}
            >
              {user.banned ? 'Забанен' : 'Активен'}
            </Badge>
          </div>

          <div className={styles['table-cell']}>
            {user.username && (
              <Badge
                key={user.username}
                customColor={userRoles[user.username]?.color ?? '#F2F4F7'}
                customTextColor={userRoles[user.username]?.textColor ?? undefined}
              >
                {userRoles[user.username]?.title}
              </Badge>
            )}
          </div>

          <div className={styles['table-cell']}>{user?.city?.name ?? 'city not found'}</div>

          <div className={styles['table-cell-buttons']}>
            <IconButton iconType='lock' size='sm' onClick={() => {}} />
            <IconButton iconType='edit'size='sm' onClick={() => {}} />
            <IconButton iconType='trash-2' size='sm' color='#B42318' onClick={() => {}} />
          </div>
        </div>
      ))}
    </div>
  );
};