import { UserPicture } from "@graphql/graphql";
import styles from "./reporter-header.module.css";
import { Avatar } from "@ui-kit";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";

type Props = {
  userPicture?: UserPicture | null;
  onBackClick: () => void;
};

export const ReporterHeader = ({ userPicture, onBackClick }: Props) => {
  return (
    <div className={styles['reporter-header']}>
      <IconButton
        className={styles['report-header__back']}
        iconType='arrow-left'
        size='xl'
        onClick={onBackClick}
      />
      <Avatar
        alt={userPicture?.username ?? 'avatar'}
        url={userPicture?.picture?.url}
        statusIcon={userPicture?.isOnline ? 'online' : undefined}
      />
      <div className={styles['reporter-header__center']}>
        <div className={styles['reporter-header__fullname']}>
          {userPicture?.firstname} {userPicture?.lastname}
        </div>
        <div className={styles['reporter-header__username']}>
          @{userPicture?.username}
        </div>
      </div>
    </div>
  );
};