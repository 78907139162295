import { useState, useEffect } from "react";
import styles from "./../../admin.module.css";
import { Report, UserPicture } from "@graphql/graphql";
import { Avatar } from "@ui-kit";
import { userStore, modalCustomDialogsStore } from "@store";
import { Loader, Badge } from "@ui-kit/components/";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { handleTypeReport } from "src/admin-panel/components/reports/helpers/handleTypeReport";
import { FinalReportModal } from "../reporter/components/final-report-modal/final-report-modal";

interface Props {
  reports: Report[];
}

export const ReportsTable = ({ reports }: Props) => {
  const [users, setUsers] = useState<{ [key: string]: UserPicture | undefined }>({});
  const [isLoading, setIsLoading] = useState(false);

  const tableHeaders = ['ID', 'Фамилия/Имя', 'Тема/Текст', 'Город', 'Статус', 'Действия'];

  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);
      const userData: { [key: string]: UserPicture | undefined } = {};
      await Promise.all(
        reports.map(async (report) => {
          if (report.reporterUsername) {
            const data = await userStore.getUserData(report.reporterUsername);
            if (data) {
              userData[report.reporterUsername] = data;
            }
          }
        })
      );
      setUsers(userData);
      setIsLoading(false);
    };
    loadUserData();
  }, [reports]);

  if (isLoading) {
    return (
      <div className={styles['loading']}>
        <Loader />
      </div>
    );
  }

  const handleButtonEditClick = (report: Report) => {
    const username = report.username ? users[report.username] : undefined;
    const reporterUsername = report.reporterUsername ? users[report.reporterUsername] : undefined;

    modalCustomDialogsStore.showDialog(
      <FinalReportModal
        report={report}
        username={username}
        reporterUsername={reporterUsername}
        typeDecision='noProof'
        closeModal={() => modalCustomDialogsStore.hideDialog()}/>,
      {
        title: 'Финальный вердикт',
        message: `Обращение #${report.id}`,
      }
    );
  };

  return (
    <div className={styles['grid-table']}>
      <div className={styles['table-header']}>
        {tableHeaders.map((header, index) => (
          <div key={index}>{header}</div>
        ))}
      </div>

      {reports.map((report) => (
        <div className={styles['table-report-info']} key={report.id}>
          <div className={styles['table-cell-id']}
            onClick={() => navigate(`/admin-reporter/${report.reporterUsername}`)}
          >
            {`${report.id?.slice(0, 15)}...`}
          </div>

          {report.reporterUsername && users[report.reporterUsername] ? (
            <div className={styles['table-username']} onClick={() => navigate(`/wall/${report.reporterUsername}`)}>
              <Avatar url={users[report.reporterUsername]?.picture?.url} size='xs' />
              <span>{users[report.reporterUsername]?.firstname}</span>
              <span>{users[report.reporterUsername]?.lastname}</span>
            </div>
          ) : (
            <span>User not found</span>
          )}

          <div className={styles['table-cell']}>
            <div className={styles['reason-header']}>{handleTypeReport(report)}</div>
            <div className={styles['table-reason']}>{report.reason}</div>
          </div>

          {report.reporterUsername && users[report.reporterUsername] ? (
            <div className={styles['table-cell']}>{users[report.reporterUsername]?.city?.name}</div>
          ) : (
            <div className={styles['table-cell']}>{'город не определен'}</div>
          )}

          <div className={styles['table-cell']}>
            <Badge
              key={report.username}
              customColor={report.isHandled ? '#ECFDF3' : '#F2F4F7'}
              customTextColor={report.isHandled ? '#027A48' : '#344054'}
            >
              {report.isHandled ? 'Завершено' : 'Ожидание'}
            </Badge>
          </div>

          <div className={styles['table-cell-buttons']}>
            <IconButton iconType='edit' size='sm' onClick={() => handleButtonEditClick(report)} />
            <IconButton iconType='trash-2' size='sm' color='#98A2B3' onClick={() => {}} />
          </div>
        </div>
      ))}
    </div>
  );
};