import styles from "../loader.module.css";
import {Loader, LoaderSize, LoaderType} from "../loader.comp";
import { Icon } from "@ui-kit";

type Props = {
  loaderSize?: LoaderSize;
  loaderType?: LoaderType;
  supportingText?: boolean;
  value: number;
};

const DownloadLoader = ({
  loaderSize,
  supportingText,
  value
}: Props) => {
  const loaderType = 'download';
  loaderSize = loaderSize ?? 'sm';

  let size = 0;
  let strokeWidth=0;
  switch (loaderSize) {
    case 'sm': size = 32; strokeWidth=3; break;
    case 'md': size = 48; strokeWidth=5; break;
    case 'lg': size = 56; strokeWidth=8; break;
    case 'xl': size = 64; strokeWidth=8; break;
  }

  const validValue = Math.min(100, Math.max(0, value))
  const radius = size / 2 - strokeWidth / 2;
  const circleFerence = 2 * Math.PI * radius;
  const viewSize = size; // + viewBoxPadding * 3;
  const center = radius + strokeWidth / 2 // + strokeWidth / 2 + viewBoxPadding;

  const isComplete = validValue === 100;

  const offset = circleFerence - (validValue / 100) * circleFerence;

  const circleStyle = {
    fill: 'none',
    stroke: 'var(--primary-600-main, #367dff)',
    strokeWidth: `${strokeWidth}`,
    strokeDasharray: `${circleFerence} ${circleFerence}`,
    strokeDashoffset: offset,
    transition: 'stroke-dashoffset 0.6s ease 0s, stroke 0.6s ease',
  };

  return (
    <Loader loaderSize={loaderSize} supportingText={"Loading..."} loaderType={loaderType}>
       <div className={`${styles['loader_download']}`}>
       {!!isComplete ? (<Icon option='circle' icon='check-circle' size='xxs' color='#039855' bgColor='#D1FADF'/>) :
        (
        <svg width={size} height={size} viewBox={`0 0 ${viewSize} ${viewSize}`}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill='none'
          stroke='#eef4ff'
          strokeWidth={strokeWidth}
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          style={circleStyle}
          transform={`rotate(-90 ${center} ${center})`}
        />
      </svg>)
      }
    </div>
    </Loader>
  );
};

export default DownloadLoader;