import { Report, UpdateReportInput } from "@graphql/graphql";
import { TypeDecision } from "../components/reporter/components/final-report-modal/final-report-modal";
import { finalReportDecision } from "./finalReportDecision";
import { deletePostFn } from "@home/helpers/delete-post";
import { commentListStore } from "@store";
import { adminBanUser } from "./adminBanUser";
import { useTranslation } from "react-i18next";

type Props = {
  report: Report;
  decisionType: TypeDecision;
}

export const UseHandleMakeDecision = () => {

  const { t } = useTranslation('notification');

  const handleMakeDecision = async ({report, decisionType}: Props) => {

  let finalDecision: string = '';

  switch (decisionType) {
    case 'noProof':
      finalDecision = t('message.report.reporter.no-proof');
      break;

    case 'warning':
      finalDecision = t('message.report.user.warning');
      break;

    case 'delete':
      if (report.post) {
        try {
          await deletePostFn(report.post);
          finalDecision = t('message.report.user.delete');
        } catch (error) {
          console.error('Error deleting post:', error);
        }

      } else if (report.comment?.id) {
        try {
          await commentListStore.deleteCommentFn(report.comment.id);
          finalDecision = t('message.report.user.delete');
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
      }
      break;

    case 'delete-warning':
      if (report.post) {
        try {
          await deletePostFn(report.post);
          finalDecision = t('message.report.user.delete-warning');
        } catch (error) {
          console.error('Error deleting post:', error);
        }

      } else if (report.comment?.id) {
        try {
          await commentListStore.deleteCommentFn(report.comment.id);
          finalDecision = t('message.report.user.delete-warning');
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      }
      break;

    case 'block':
      if (!report.post && !report.comment && report.username) {
        try {
          await adminBanUser(report.username)
          finalDecision = t('message.report.user.block');
        } catch (error) {
          console.error('Error blocking user:', error);
        }
      }
    break;

    case 'delete-block':
      if (report.post && report.username) {
        try {
          await deletePostFn(report.post);
          await adminBanUser(report.username!);
          finalDecision = t('message.report.user.delete-block');
        } catch (error) {
          console.error('Error deleting post and blocking user:', error);
        }

      } else if (report.comment?.id && report.username) {
        try {
          await commentListStore.deleteCommentFn(report.comment.id);
          await adminBanUser(report.username!);
          finalDecision = t('message.report.user.delete-block');
        } catch (error) {
          console.error('Error deleting comment and blocking user:', error);
        }
      }
      break;

    default:
      return;
  }

  if (finalDecision) {

    const input: UpdateReportInput = {
      id: report.id,
      isApprooved: true,
      decisionType: decisionType,
      decisionText: finalDecision,
    };

    const result = await finalReportDecision(input);
    if (result) {
      console.log('Решение по жалобе успешно обработано:', result);
    }
  }
};

  return { handleMakeDecision };
};