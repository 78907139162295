import { createDate } from "./createDate"

export const formatDate = (date: Date, format: string) =>{
  const dateCurrent= createDate({date});
  return format
    .replace(/\bYYYY\b/, dateCurrent.year.toString())
    .replace(/\bYYY\b/, dateCurrent.yearShort)
    .replace(/\bWW\b/, dateCurrent.week.toString().padStart(2, '0'))
    .replace(/\bW\b/, dateCurrent.week.toString())
    .replace(/\bDDDD\b/, dateCurrent.day)
    .replace(/\bDDD\b/, dateCurrent.dayShort)
    .replace(/\bDD\b/, dateCurrent.dayNumber.toString().padStart(2, '0'))
    .replace(/\bD\b/, dateCurrent.dayNumber.toString())
    .replace(/\bMMMM\b/, dateCurrent.month)
    .replace(/\bMMM\b/, dateCurrent.monthShort)
    .replace(/\bMM\b/, dateCurrent.monthNumber.toString().padStart(2, '0'))
    .replace(/\bM\b/, dateCurrent.monthNumber.toString());
}