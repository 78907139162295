import { useEffect, useState, useCallback } from "react";
import styles from "./admin.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components/header/header.comp";

export const AdminPanelPage = observer(() => {
  return (
    <>
      <section className={styles['admin-page']}>
        <Header
          className={styles['header-admin']}
          title={'Дашборд'}
          hideAvatar={true}
          hideBackArrow={true}
        />
        <section className={styles['dashboard-container']}>
          Инфографика
        </section>
      </section>
    </>
  );
});
