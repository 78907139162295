import { UserPicture } from "@graphql/graphql";
import styles from "./report-profile.module.css";
import { Avatar } from "@ui-kit";
import { useNavigate } from "react-router-dom";

type Props = {
  username?: UserPicture;
};

export const ReportProfile = ({ username }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles['username-header']}
      onClick={() => navigate(`/wall/${username?.username}`)}
    >
      <Avatar
        alt={username?.username ?? 'avatar'}
        url={username?.picture?.url}
        statusIcon={username?.isOnline ? 'online' : undefined}
      />
      <div className={styles['username-header__center']}>
        <div className={styles['username-header__fullname']}>
          {username?.firstname} {username?.lastname}
          <div className={styles['username-city-text']}>
            {username?.city?.longName}
          </div>
        </div>
        <div className={styles['username-header__username']}>
          @{username?.username}
        </div>
      </div>
    </div>
  );
};