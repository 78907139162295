import { Notification } from "@graphql/graphql";
import { HOST_URL } from "@shared/data/api.constants";

export const getNotificationLink = (item: Notification) => {
  switch(item.notifyType) {
    case 'post-reaction':
      return item.post
        ? `${HOST_URL}/post/${item.post?.id}`
        : undefined;
    case 'comment-reaction':
      return item.comment
        ? `${HOST_URL}/post/${item.comment?.postId}?comment=${item.comment?.id}`
        : undefined;
    case 'message':
      return item.message
        ? `${HOST_URL}/messages?chat=${item.from?.username}`
        : undefined;
    case 'message-reaction':
      return item.message
        ? `${HOST_URL}/messages?chat=${item.from?.username}`
        : undefined;
    case 'subscription':
      return undefined;
    case 'tagged-post':
      return item.post
        ? `${HOST_URL}/post/${item.post?.id}`
        : undefined;
    case 'tagged-comment':
      return item.comment
        ? `${HOST_URL}/post/${item.comment?.postId}?comment=${item.comment?.id}`
        : undefined;
    case 'new-post':
      return item.post
        ? `${HOST_URL}/post/${item.post?.id}`
        : undefined;
    case 'answer-comment':
      return item.answerForComment
        ? `${HOST_URL}/post/${item.answerForComment?.postId}?subComment=${item.comment?.id}&comment=${item.answerForComment?.id}`
        : undefined;
    case 'comment':
      return item.comment
        ? `${HOST_URL}/post/${item.comment?.postId}?comment=${item.comment?.id}`
        : undefined;
    case 'report-to-user':
      return `${HOST_URL}/agreement`
    default:
      return undefined;
  }
}
