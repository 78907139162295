import { UserPicture } from "@graphql/graphql";
import styles from "./report-header.module.css";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@ui-kit";

type Props = {
  username?: UserPicture | null;
  reporterUsername?: UserPicture | null;
  className?: React.HTMLAttributes<HTMLDivElement> | string;
};

export const ReportHeader = ({ reporterUsername, username, className }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={`${styles['report-header']} ${className ? className : ''}`}>
      <Avatar
        size='xs'
        alt={reporterUsername?.username ?? 'avatar'}
        url={reporterUsername?.picture?.url}
        statusIcon={reporterUsername?.isOnline ? 'online' : undefined}
      />

      <div className={styles['report-header__center']}>
        <div className={styles['report-header__fullname']}>
          {reporterUsername?.firstname} {reporterUsername?.lastname}
        </div>
      </div>

      <p className={styles['report-header__report']}>оставляет жалобу на профиль</p>
      <div className={styles['report-header']}>
        <Avatar
          size='xs'
          alt={username?.username ?? 'avatar'}
          url={username?.picture?.url}
          statusIcon={username?.isOnline ? 'online' : undefined}
        />
        <div className={styles['report-header__center']}>
          <div className={styles['report-header__fullname']}>
            {username?.firstname} {username?.lastname}
          </div>
        </div>
      </div>
    </div>
  );
};
