import { Report } from "@graphql/graphql";
import { Button } from "@ui-kit";
import { getReportContent } from "../../../../helpers/getReportContent";

type Props = {
  report: Report;
};

export const FollowReportButton = ({ report }: Props) => {

  const handleButtonClick = () => {
    const reportContent = getReportContent(report);
    if (reportContent) {
      window.open(reportContent, '_blank');
    }
  };

  return (
    <Button
      hierarchy='secondary-gray'
      size='sm'
      onClick={handleButtonClick}
    >
      Перейти
    </Button>
  );
};
