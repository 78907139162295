import { useEffect, useState } from "react";
import styles from "./search-user-input.module.css";
import { DropdownInput } from "../../../ecitty-ui/components/input/dropdown-input/dropdown-input";
import { SEARCH_MIN_LENGTH, SEARCH_TIMEOUT_MS } from "@shared/data";
import { SearchResults, UserPicture } from "@graphql/graphql";
import { searchUsers } from "src/messenger/helpers/search-users";
import { userToMenuItem } from "src/messenger/helpers/user-to-menu-item";
import {  modalCustomDialogsStore, userStore } from "@store";
import { Button } from "@ui-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


let timeoutFn: any;

type Props = {
  onSelect?: (item: UserPicture) => void,
}

export const SearchUserInput = ({ onSelect }: Props) => {
  const [users, setUsers] = useState<UserPicture[]>([]);
  const [currentUser, setCurrentUser] = useState<UserPicture>();
  const [_currentUser, _setCurrentUser] = useState<UserPicture>();
  const [text, setText] = useState('');

  const { t } = useTranslation('messenger');

  const navigate = useNavigate();

  useEffect(() => {
    if (text === '' || text.length < SEARCH_MIN_LENGTH) {
      return;
    }
    if(!!timeoutFn) {
      clearTimeout(timeoutFn)
    };
    timeoutFn = setTimeout(() => {
      searchUsers(text)
        .then((res: SearchResults) => {
          setUsers(() => res.userItems as UserPicture[]);
        });
    }, SEARCH_TIMEOUT_MS);
  }, [text]);

  useEffect(() => onSelect && currentUser && onSelect(currentUser), [currentUser]);

  const onClick = () => {
    modalCustomDialogsStore.hideDialog();
    navigate(`/messages?chat=${currentUser?.username}`);
  }

  return <div className={styles['search-user-input']}>
    <DropdownInput
      label='Поиск'
      onchange={setText}
      text={text}
      items={users
        .filter((user) => user.username !== userStore.currentUser?.username)
        .map((user) => userToMenuItem(user, currentUser))
      }
      selecteItem={(item) => {
        setCurrentUser(users.find((user) => user.username === item.key));
      }}
      item={currentUser ? userToMenuItem(currentUser) : undefined}
      type='user'
      />
    <Button disabled={!currentUser?.username} onClick={onClick}>{t('button.writeMessage')}</Button>
  </div>
}