import { getReportsByReporter } from "@graphql/docs/queries";
import { apolloClient } from "src/apollo-client";

export async function fetchGetReportsByReporter(username: string) {
  try {
    const { data } = await apolloClient.query({
      query: getReportsByReporter,
      variables: { username },
    });
    return data.getReportsByReporter;
  } catch (error) {
    console.error('Error fetching all reports by reporter:', error);
    throw error;
  }
};