import styles from "./final-report-modal.module.css";
import React, { useEffect, useState } from "react";
import { Report, UserPicture } from "@graphql/graphql";
import { Button, Badge } from "@ui-kit";
import { ReportHeader } from "../../components/report-header/report-header.comp";
import { ReportProfile } from "../report-profile/report-profile.comp";
import { formatDate } from "@ui-kit/components/calendar/helpers";
import { formatTime } from "src/admin-panel/components/reports/helpers/formatTime";
import { UseHandleMakeDecision } from "src/admin-panel/components/reports/helpers/useHandleMakeDecision";

export type TypeDecision = 'noProof' | 'warning' | 'block' | 'delete' | 'delete-block' | 'delete-warning';

type Props = {
  report: Report;
  typeDecision: TypeDecision;
  username?: UserPicture | null;
  reporterUsername?: UserPicture | null;
  closeModal: () => void;
}

export const FinalReportModal = ({ report, typeDecision, username, reporterUsername, closeModal }: Props) => {

  const [currentDecision, setCurrentDecision] = useState<TypeDecision>(typeDecision);
  const { handleMakeDecision } = UseHandleMakeDecision();

  const tableData = [
    { header: 'Дата', value: report.createdAt && formatDate(new Date(report.createdAt), 'D MMM YYYY')},
    { header: 'Время', value: report?.createdAt && formatTime(report?.createdAt)},
    { header: 'От кого:', value: `${reporterUsername?.firstname} ${reporterUsername?.lastname}` },
    { header: 'На кого:', value: `${username?.firstname} ${username?.lastname}` },
    { header: 'Страна', value: username?.city?.country },
    { header: 'Город', value: username?.city?.longName },
    { header: 'Почта', value: username?.email },
    { header: 'Статус', value: report.isHandled },
  ];

  const handleDecisionClick = (decision: TypeDecision) => {
    setCurrentDecision(decision);
  };

  const handleFinalDecisionClick = async (report: Report, decision: TypeDecision) => {

    await handleMakeDecision({ report, decisionType: currentDecision }).then(() => {
      closeModal();
    });
  };

  return (
    <div className={styles['report-container']}>
      {username && reporterUsername &&
        <>
          <ReportHeader key={report.id} reporterUsername={reporterUsername} username={username} />
            <div className={styles['report-content']}>
              {report.username && <ReportProfile username={username} />}
          </div>
        </>
      }

      <div className={styles['report-content__selector']}>
        <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'noProof' ? 'check' : undefined}
          hierarchy={currentDecision === 'noProof' ? 'primary' :'secondary-gray'}
          size='sm'
          onClick={() => handleDecisionClick('noProof')}
        >
          Нет доказательств
        </Button>

        <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'warning' ? 'check' : undefined}
          hierarchy={currentDecision === 'warning' ? 'primary' :'secondary-gray'}
          size='sm'
          onClick={() => handleDecisionClick('warning')}
        >
          Предупреждение
        </Button>

        {(report.post || report.comment) &&
        <>
        <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'delete' ? 'check' : undefined}
          hierarchy={currentDecision === 'delete' ? 'primary' : 'secondary-gray'}
          destructive={true} size='sm'
          onClick={() => handleDecisionClick('delete')}
        >
          Удалить
        </Button>

        <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'delete-warning' ? 'check' : undefined}
          hierarchy={currentDecision === 'delete-warning' ? 'primary' : 'secondary-gray'}
          destructive={true} size='sm'
          onClick={() => handleDecisionClick('delete-warning')}
        >
          Удалить и предупредить
        </Button>

        <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'delete-block' ? 'check' : undefined}
          hierarchy={currentDecision === 'delete-block' ? 'primary' : 'secondary-gray'}
          destructive={true} size='sm'
          onClick={() => handleDecisionClick('delete-block')}
        >
          Удалить и заблокировать
        </Button>
        </>}

        {(!report.post && !report.comment) && <Button
          className={styles['report-content__button']}
          iconType={currentDecision === 'block' ? 'check' : undefined}
          hierarchy={currentDecision === 'block' ? 'primary' : 'secondary-gray'}
          destructive={true} size='sm'
          onClick={() => handleDecisionClick('block')}
        >
          Заблокировать
        </Button>}
      </div>

      <div className={styles['report-table-info']}>
        <div className={styles['grid-table']}>
          {tableData.map((item, index) => (
            <React.Fragment key={`modal${report.id}-${index}`}>
              <div className={styles['table-header']}>{item.header}</div>
              {item.header === 'Статус' ? <div className={styles['table-cell']}>
              <Badge
                key={report.username}
                customColor={report.isHandled ? '#ECFDF3' : '#F2F4F7'}
                customTextColor={report.isHandled ? '#027A48' : '#344054'}
              >
                {report.isHandled ? 'Завершено' : 'Ожидание'}
              </Badge>
              </div> : <div className={styles['table-cell']}>{item.value}</div>}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={styles['report-table-footer']}>
        <Button
          hierarchy={'primary'}
          size='md'
          onClick={() => handleFinalDecisionClick(report, currentDecision)}
        >
          Вынести решение
        </Button>
        <Button
          hierarchy={'secondary-color'}
          size='md'
          onClick={() => closeModal()}
        >
          Закрыть
        </Button>
      </div>
    </div>
  )
};