import styles from "./button.module.css";
import { IconTypes } from 'src/assets/gray-icons';
import { Loader } from "../loader/loader.comp";
import { Icon } from "@ui-kit";

export type ButtonSize = "sm" | "ss" | "md" | "lg" | "xl" | "2xl" |"3xl";

export type HierarchyType =
  | "primary"
  | "secondary-color"
  | "primary-gray"
  | "secondary-gray"
  | "tertiary-color"
  | "tertiary-gray"
  | "quaternary-color"
  | "quinary-color"
  | "link-color"
  | "link-gray";

export type IconOrder = "leading" | "trailing" | "dot" | "only";


type Props = {
  onClick?: (e: any) => void;
  className?: React.HTMLAttributes<HTMLDivElement>|string;
  children?: any;
  size?: ButtonSize;
  hierarchy?: HierarchyType;
  destructive?: boolean;
  iconType?: IconTypes;
  disabled?: boolean;
  rightIcon?: boolean;
  color?: string;
  type?: 'button'|'reset'|'submit';
  loading?: boolean;
};

export const Button = ({
  children,
  onClick,
  className,
  size,
  hierarchy,
  destructive,
  iconType,
  disabled,
  rightIcon,
  type,
  loading,
}: Props) => {
  if (!size) {
    size = 'md';
  }

  if (!hierarchy) {
    hierarchy = 'primary';
  }

  if (!onClick) {
    onClick = () => {};
  }

  let sizeCssClass = '';
  let color = '';
  let iconSize = 'xxs-plus';

  switch (size) {
    case 'sm': sizeCssClass = styles['button_size-sm']; iconSize = 'xxs-plus'; break;
    case 'md': sizeCssClass = styles['button_size-md']; iconSize = 'xxs-plus'; break;
    case 'lg': sizeCssClass = styles['button_size-lg']; iconSize = 'xxs-plus'; break;
    case 'xl': sizeCssClass = styles['button_size-xl']; iconSize = 'xxs-plus'; break;
    case '2xl': sizeCssClass = styles['button_size-2xl']; iconSize = 'xs'; break;
    case '3xl': sizeCssClass = styles['button_size-3xl']; iconSize = 'xxs-plus'; break;
  }

  let hierarchyCssClass = '';
  switch (hierarchy) {
    case 'primary':
      hierarchyCssClass =
        styles[`button_primary${destructive ? '-destructive' : ''}`];
        color = '#ffffff';
      break;
    case 'secondary-color':
      hierarchyCssClass =
        styles[`button_secondary-color${destructive ? '-destructive' : ''}`];
        color = disabled ? '#ADCAFF' : '#1E5BCB';
      break;
    case 'secondary-gray':
      hierarchyCssClass =
        styles[`button_secondary-gray${destructive ? '-destructive' : ''}`];
        color = disabled ? '#D0D5DD' : '#344054';
      break;
    case 'primary-gray':
      hierarchyCssClass =
        styles[`button_primary-gray${destructive ? '-destructive' : ''}`];
        color = disabled ? '#D0D5DD' : '#344054';
      break;
    case 'tertiary-color':
      hierarchyCssClass =
        styles[`button_tertiary-color${destructive ? '-destructive' : ''}`];
        color = disabled ? '#CADCFC' : '#1E5BCB';
      break;
    case 'tertiary-gray':
      hierarchyCssClass =
        styles[`button_tertiary-gray${destructive ? '-destructive' : ''}`];
        color = disabled ? '#D0D5DD' : '#98A2B3';
      break;
    case 'link-color':
      hierarchyCssClass =
        styles[`button_link-color${destructive ? '-destructive' : ''}`];
        color = '#1E5BCB';
      break;
    case 'quaternary-color':
      hierarchyCssClass =
        styles[`button_quaternary-color${destructive ? '-destructive' : ''}`];
        color = disabled ? '#D0D5DD' : '#98A2B3';
      break;
    case 'quinary-color':
      hierarchyCssClass =
        styles[`button_quinary-color${destructive ? '-destructive' : ''}`];
        color = disabled ? '#ADCAFF' : '#1E5BCB';
        break;
    case 'link-gray':
      hierarchyCssClass =
        styles[`button_link-gray${destructive ? '-destructive' : ''}`];
        color = disabled ? '#D0D5DD' : '#98A2B3';
      break;
  }

  if (destructive) {
    color = '#B42318';
  }

  if (!children) {
    hierarchyCssClass += ` ${styles[`button_size-${size}-icon`]}`
  }

  return (
    <button
      type={type}
      className={`${styles.button} ${sizeCssClass} ${hierarchyCssClass}
      ${className ? className : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {!loading
        ? <>
          {iconType && !rightIcon &&
            <Icon icon={iconType} color={color} size={iconSize as 'xxs-plus' | 'xs'} />}
          {children ?? ''}
          {iconType && rightIcon &&
            <Icon icon={iconType} color={color} size={iconSize as 'xxs-plus' | 'xs'} />}
        </>
        : <Loader loaderSize='xs' />
      }
    </button>
  );
};
